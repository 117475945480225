<template>
    <div>
        <b-sidebar id="add_marketing" backdrop width="50rem" style="direction:ltr" aria-labelledby="sidebar-no-header-title" no-header right :title="formtitle" shadow>
        <template #default="{ hide }">
            <div class="m-1 text-start" style="direction:rtl">
                <div style="width:120px;margin-right:10px;background:#28467c;color:#FFF;border-radius:5px" class="btn-primary btn-sm">
                    <span>{{formtitle}}</span>
                </div>
                <div @click="hide" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                    <span>اغلاق</span>
                </div>
            </div>
            <v-divider></v-divider>
            <div class="px-3 py-2" style="direction:rtl">
                <v-row>
                    <v-col cols="12" md="4" sm="6">
                        <v-text-field
                            label="عنوان الحملة *"
                            v-model="camp.title"
                            >{{ camp.title }}</v-text-field>
                    </v-col>
                
                    <v-col cols="12" md="4" sm="6">
                        <v-select
                            item-text="text"
                            item-value="value"
                            v-model="camp.types"
                            :items="canptypes"
                            label="نوع الحملة"
                            @change="mylo"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="4" sm="6">
                        <v-dialog
                            ref="dialog"
                            v-model="modal"
                            :return-value.sync="date"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="date"
                                label="تاريخ الارسال"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="date"
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="modal = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.dialog.save(date)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                </v-row>
                <v-row v-if="showWhatsapp">
                    <v-col cols="12">
                        <input
                            type="file"
                            v-if="showImgs"
                            multiple
                            accept="image/*"
                            label="اختر الصورة"
                            style="width:98%;border:1px solid #ccc;padding:5px;"
                            ref="attached"
                            @change="selectFiles()"
                            >
                        
                        <input
                            type="file"
                            v-if="showDocs"
                            multiple
                            label="اختر الملف"
                            style="width:98%;border:1px solid #ccc;padding:5px;"
                            ref="attached"
                            @change="selectFiles()"
                            >
                        <v-text-field
                            v-if="showVedio"
                            label="رابط الفديو"
                            v-model="vediourl"
                            >{{ vediourl }}</v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6" sm="12">
                        <v-textarea
                        v-model="txtToSent"
                        color="black"
                        label="الرسالة النصية"
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                        <v-textarea
                        v-model="mobiles"
                        color="black"
                        label="ارقام الجوالات"
                        ></v-textarea>
                    </v-col>
                </v-row>
            </div>
            <template class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                <b-button type="button" variant="success" @click='addCampaign()' class="ma-2" style="width:100px;">تحميل</b-button>
                </div>
            </template>
        </template>
        </b-sidebar>
        <vue-snotify></vue-snotify>
    </div>
</template>

<script>
import {SnotifyPosition, SnotifyStyle} from 'vue-snotify';
import axios from 'axios';
export default {
    data() {
        return {
            showWhatsapp: false,
            showVedio: false,
            showDocs: false,
            showImgs: false,
            attached: '',
            vediourl: '',
            txtToSent: '',
            mobiles: '',
            formtitle: 'إضافة حملة اعلانية',
            camp: {
                title:'',
                types:{text:'رسائل نصية', value:1},
            },
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            modal: false,
            canptypes: [
                {
                    text: 'رسائل نصية',
                    value: 1,
                },
                {
                    text: 'رسائل واتس آب',
                    value: 2,
                },
                {
                    text: 'صورة عبر الواتس آب',
                    value: 3,
                },
                {
                    text: 'فديو عبر الواتس آب',
                    value: 4,
                },
                {
                    text: 'ملف عبر الواتس آب',
                    value: 5,
                }
            ]
        }
    },
    methods: {
        resetValues(){
            this.camp.title = '';
            this.camp.types = {
                text: 'رسائل نصية',
                value: 1,
            };
            this.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            this.mobiles = '';
            this.txtToSent = '';
            this.vediourl = '';
            this.attached = '';
        },
        mylo(){
            if(this.camp.types != 1){
                if (this.$snotify.notifications.length > 0) {
                    this.$snotify.notifications.forEach(notification => {
                        this.$snotify.remove(notification.id)
                    });
                }
                this.$snotify.create({
                    title: 'WhatsApp API',
                    body: 'أنت غير مشترك بخدمة WhatsApp API للاشتراك الاتصال بالرقم 0565100268',
                    config: {
                        position: SnotifyPosition.leftTop,
                        type: SnotifyStyle.error,
                        timeout: 10000,
                        showProgressBar: true,
                        closeOnClick: true,
                    }
                })
                this.camp.types = {
                    text: 'رسائل نصية',
                    value: 1,
                }
            }
            if(this.camp.types == 2){
                this.showWhatsapp = false;
                this.showImgs = false;
                this.showVedio = false;
                this.showDocs = false;
            }
            else if(this.camp.types == 3){
                this.showWhatsapp = true;
                this.showImgs = true;
                this.showVedio = false;
                this.showDocs = false;
            }
            else if(this.camp.types == 4){
                this.showWhatsapp = true;
                this.showImgs = false;
                this.showDocs = true;
                this.showVedio = false;
            }
            else if(this.camp.types == 5){
                this.showWhatsapp = true;
                this.showImgs = false;
                this.showDocs = false;
                this.showVedio = true;
            }
            else {
                this.showWhatsapp = false;
                this.showImgs = false;
                this.showDocs = false;
                this.showVedio = false;
            }
        },
        selectFiles(){
            //
        },
        addCampaign(){
            const post = new FormData();
            post.append("type",'addCampaign');
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[title]",this.camp.title);
            post.append("data[types]",this.camp.types.value ?? this.camp.types);
            post.append("data[img]",this.attached);
            post.append("data[vediourl]",this.vediourl);
            post.append("data[mobiles]",this.mobiles)
            post.append("data[body]",this.txtToSent);
            post.append("data[sent_at]",this.date);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (resposnse) => {
                    const res = resposnse.data;
                    // // console.log(res);
                    this.resetValues();
                    if(res.error.number == 200){
                        this.$snotify.create({
                            title: 'إضافة حملة اعلانية',
                            body: 'تمت اضافة الحملة بنجاح',
                            config: {
                                position: SnotifyPosition.leftTop,
                                type: SnotifyStyle.success,
                                timeout: 10000,
                                showProgressBar: true,
                                closeOnClick: true,
                            }
                        })
                    }else{
                        this.$snotify.error({
                            title: 'إضافة حملة اعلانية',
                            body: 'حصل خطأ اثناء اضافة الحملة',
                            config: {
                                position: SnotifyPosition.leftTop,
                                type: SnotifyStyle.success,
                                timeout: 10000,
                                showProgressBar: true,
                                closeOnClick: true,
                            }
                        })
                    }
                }
            )
        }
    },
    created() {
       // this.camp.types = {text:'رسائل واتس آب', value:'2'};
    },
}
</script>